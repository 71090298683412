import React from 'react';

export function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
      const cellValue = row.values[id];
      const numericValue = parseFloat(cellValue.replace('%', ''));
      return numericValue >= filterValue;
    });
  }

  export function SliderColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id }
  }) {

    const filteredValues = preFilteredRows.filter(row => !isNaN(parseFloat(row.values[id])));
  
    const [min, max] = [0, 100];
  
    return (
      <div className="slider-filter-container">
        <input
          type="range"
          min={min}
          max={max}
          value={filterValue || min}
          onChange={e => {
            setFilter(parseInt(e.target.value, 10));
          }}
        />
      </div>
    );
  }

  export function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
  }) {
    const [min, max] = React.useMemo(() => {
      let min = Infinity;
      let max = -Infinity;
      preFilteredRows.forEach((row) => {
        const val = row.values[id];
        if (typeof val === 'number') {
          min = Math.min(val, min);
          max = Math.max(val, max);
        }
      });
      return [min, max];
    }, [id, preFilteredRows]);
  
    return (
      <div>
        <input
          value={filterValue[1] || ''}
          className="table-number"
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
          }}
          placeholder="Max"
        />
      </div>
    );
  }
  

  
