import React, {useState, useRef} from "react";
import { useSticky } from 'react-table-sticky'
import { useTable, useFilters, useSortBy } from 'react-table';
import { matchSorter } from 'match-sorter';
import html2canvas from "html2canvas";

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase()): true;});}}),[]);

  const defaultColumn = React.useMemo(
    () => ({Filter: DefaultColumnFilter}),[]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
    },
    useFilters,
    useSortBy,
    useSticky,
  );

  const [currentPage, setCurrentPage] = useState(0); // Ajoutez ces déclarations
  const pageSize = 26;

  const firstPageRows = rows.slice(currentPage * pageSize, (currentPage + 1) * pageSize);

  const nextPage = () => {
    if (currentPage < Math.ceil(data.length / pageSize) - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);}};

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter }}){const count = preFilteredRows.length;

return (
  <input
    value={filterValue || ""}
    onChange={e => {
      setFilter(e.target.value || undefined);}}
      placeholder={`Search ${count} records...`}
    />);}

return (
<>

  <div id="captureArea" style={{ display: "none" }}></div>
  
  <table {...getTableProps()}>
    <thead>
      {headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => (
            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
              {column.render("Header")}
                <span>{column.isSorted ? (column.isSortedDesc ? " ↓" : " ↑") : ""}</span>
                <div>{column.canFilter ? column.render("Filter") : null}</div>
            </th>))}
            </tr>))}
    </thead>
        
    <tbody {...getTableBodyProps()}>
      {firstPageRows.map((row, i) => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps()}>
            {row.cells.map(cell => {
              return (
                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>);})}
          </tr>);})}
    </tbody>
  </table>
  <div className="footer">
      <button className="button-paginate" onClick={prevPage} disabled={currentPage === 0}><span class="material-icons">arrow_back</span></button>
      <button className="button-paginate" onClick={nextPage} disabled={currentPage === Math.ceil(rows.length / pageSize) - 1}><span class="material-icons">arrow_forward</span></button>
      <div>{rows.length} players available with selected filters</div>
  </div>

</>);}

function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}
filterGreaterThan.autoRemove = val => typeof val !== "number";

export default Table;