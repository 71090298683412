// AFFICHAGE DES MATCHS SELON VARIABLES

export const filterGameData = (games, minsplayed, starter) => {
  const organizedData = { so5scores: {}, aascores: {} };
  for (const game of games) {
    const playerId = game.player_id;
    const so5score = game.so5score;
    const aascore = game.aascore;
    const minsPlayed = game.minsplayed;
    const isStarter = game.starter === 1;
    if (!organizedData.so5scores[playerId]) {
      organizedData.so5scores[playerId] = [];
    }
    if (!organizedData.aascores[playerId]) {
      organizedData.aascores[playerId] = [];
    }
    // Appliquer les filtres
    if (minsPlayed >= minsplayed && (!starter || isStarter)) {
      organizedData.so5scores[playerId].push(so5score);
      organizedData.aascores[playerId].push(aascore);
    } else {
      organizedData.so5scores[playerId].push('N/A');
      organizedData.aascores[playerId].push('N/A');
    }
  }
  return organizedData;
};

// MOYENNES 

export const calculateAverage = (validScores, numMatches) => {
  const numericScores = validScores.map((score) => (score !== 'N/A' ? parseFloat(score) : NaN));
  const validNumericScores = numericScores.filter((score) => !isNaN(score));
  if (validNumericScores.length === 0) {
    return NaN;}
  const average = validNumericScores
    .slice(0, numMatches)
    .reduce((sum, score) => sum + score, 0) / validNumericScores.length;
  return average;};


// FREQUENCES

export const calculateFrequency = (validScores, threshold) => {
  const scoresAboveThreshold = validScores.filter((score) => score >= threshold);
  const totalValidScores = validScores.filter((score) => score !== 'N/A').length;
  if (totalValidScores === 0) {return 0;}
  const percentage = (scoresAboveThreshold.length / totalValidScores) * 100;
  return percentage;};

// PLAYED

export const calculatePlayed = (scores, numMatches) => {
  const validScores = scores.filter((score) => !isNaN(score) && score !== 'N/A');
  if (validScores.length === 0) {return 0;}
  const playedPercentage = (validScores.length / numMatches) * 100;
  return playedPercentage;};

// Calculer NB si Gameweek

export const calculateNumMatches = (games, playerId, gameweek) => {
  const matchesForPlayer = games.filter((game) => game.player_id === playerId && game.gameweek >= gameweek);
  return matchesForPlayer.length;
};

// PERF INDEX

export const calculatePerfIndex = (validScores) => {
  let minusCount = 0;   // pour les scores < 45 mais >= 35
  let doubleMinusCount = 0;   // pour les scores < 35
  let plusPlusCount = 0; // pour les scores entre 60 et 75
  let plusPlusPlusCount = 0; // pour les scores > 75

  validScores.forEach(score => {
    if (score >= 35 && score < 45) minusCount++;
    else if (score < 35) doubleMinusCount++;
    else if (score >= 60 && score < 75) plusPlusCount++;
    else if (score > 75) plusPlusPlusCount++;
  });

  const totalScores = validScores.length;
  const weightedTotal = doubleMinusCount * 2 + minusCount + plusPlusCount * 2 + plusPlusPlusCount * 3;

  // Ce calcul garantit que la valeur sera entre 0 et 100.
  const perfIndex = 100 * (weightedTotal / (totalScores * 3));

  return Math.round(Math.max(0, perfIndex));
};










