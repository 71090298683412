import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom"
import Players from "./pages/Players";
import Setup from "./pages/Setup"; 

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Setup />} />
          <Route path="/players" element={<Players />} /> {/* Ajoutez cet itinéraire pour la page de configuration */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

