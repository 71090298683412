import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Table from '../components/Table';
import '../index.css';
import { calculateAge } from '../utilities/calculateAge';
import { calculateNumMatches, filterGameData, calculateAverage, calculateFrequency, calculatePlayed, calculatePerfIndex } from '../utilities/filter';
import { useSearchParams } from 'react-router-dom';
import { SliderColumnFilter, filterGreaterThan, NumberRangeColumnFilter } from '../components/columnFilter';
import { Header } from '../components/headerfooter';
import html2canvas from "html2canvas";
import logo from './logo.png';


const API_URL = "https://api.sorarerankings.com";
// "https://api.sorarerankings.com" / "http://localhost:8800"

const Players = () => {
  const [players, setPlayers] = useState([]);
  const [games, setGames] = useState([]);
  const [filteredPlayersWithScores, setFilteredPlayersWithScores] = useState([]);
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);

// VALEURS PAR DEFAUT
  const minsplayed = parseInt(searchParams.get('minsplayed'), 10) || 1;
  const starter = searchParams.get('starter') === 'true';
  const gameweek = parseInt(searchParams.get('gameweek'), 10) || 0;
  const so5Frequ = parseInt(searchParams.get('so5Frequ'), 10) || 0;
  const aaFrequ = parseInt(searchParams.get('aaFrequ'), 10) || 0;

  const isSinceGW = searchParams.get('isSinceGW') === 'true';
  let numMatches;
  if (isSinceGW) {
    if (games.length > 0) {
      const playerId = games[0].player_id; 
      numMatches = calculateNumMatches(games, playerId, gameweek);}} else {numMatches = parseInt(searchParams.get('numMatches'), 10) || 0;}

  const league = searchParams.get('league') || '';
  const country = searchParams.get('country') || '';
  const club = searchParams.get('club') || '';
  const position = searchParams.get('position') || '';
  const maxlimited = searchParams.get('maxlimited') || '';
  const maxrare = searchParams.get('maxrare') || '';

// RECUPERER LES DONNEES
useEffect(() => {
  const fetchData = async () => {
    setIsLoading(true); 
    try {
      const playersResponse = await axios.get(`${API_URL}/players`, {
        params: {
          league,
          country,
          club,
          position,
          maxlimited,
          maxrare,
        },
      });

      const gamesResponse = await axios.get(`${API_URL}/games`);

      setPlayers(playersResponse.data);
      setGames(gamesResponse.data);
    } catch (err) {
      console.error(err);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  };

  fetchData();
}, [league, country, club, maxlimited, maxrare]);

// INSERER LES SCORES 

useEffect(() => {
  const filteredGameData = filterGameData(games, minsplayed, starter);
  setFilteredPlayersWithScores(
    players.map((player) => {
      const playerId = player.id;
      const playerSO5Scores = filteredGameData.so5scores[playerId] || [];
      const playerAASScores = filteredGameData.aascores[playerId] || [];
      const playerWithScores = { ...player };
      for (let i = 0; i < 40; i++) {
        playerWithScores[`so5score${i + 1}`] = playerSO5Scores[i] || '';
        playerWithScores[`aascore${i + 1}`] = playerAASScores[i] || '';}
      return playerWithScores;
    }));
  }, [games, minsplayed, starter]);

// ZONE IMAGE

const handleCapture = () => {
  const captureArea = document.getElementById("captureArea");
  if (!captureArea) {
    console.error('Capture area not found in the document.');
    return;
  }
  
  captureArea.innerHTML = '';
  const captureContainer = document.createElement("div");
  const marginWrapper = document.createElement("div");
  marginWrapper.classList.add("margin-wrapper");

// Ajout logo

  if (marginWrapper) {
    const imgElement = document.createElement('img');
    imgElement.setAttribute('class', 'custom-logo');
    imgElement.style.height = '30px'; 
    imgElement.style.width = 'auto'; 
    imgElement.setAttribute('alt', 'Logo');
    imgElement.src = logo;
    marginWrapper.appendChild(imgElement);
  } else {
    console.error('.margin-wrapper not found in the document.');
  }

// Ajout filtres 

  const selectedFilters = document.querySelector(".selected-filters");
  if (selectedFilters) {
    const selectedFiltersClone = selectedFilters.cloneNode(true);
    marginWrapper.appendChild(selectedFiltersClone);
  } else {
    console.error('.selected-filters not found in the document.');
  }

  // Ajouter la table clonée
  const table = document.querySelector("table");
  if (table) {
    const tableCopy = table.cloneNode(true);
    tableCopy.classList.add("cloned-table-for-capture");
    // Définir les colonnes à masquer
    const columnsToHide = [0,1,7,12,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97];
    tableCopy.querySelectorAll("th, td").forEach((cell) => {
      const columnIndex = cell.cellIndex;
      if (columnsToHide.includes(columnIndex)) {
        cell.classList.add("hidden-on-image");
      }
    });
    marginWrapper.appendChild(tableCopy);
  } else {
    console.error('Table not found in the document.');
  }

  // Ajouter le footer
  const footer = document.createElement("div");
  footer.textContent = "sorarerankings.com"; 
  footer.classList.add("capture-footer");
  marginWrapper.appendChild(footer);

  // Préparer le conteneur pour la capture
  captureContainer.appendChild(marginWrapper);
  captureArea.appendChild(captureContainer);
  prepareCaptureArea(captureArea);

  // Effectuer la capture
  html2canvas(captureContainer, { scale: 2 }).then(canvas => {
    downloadCanvasAsImage(canvas, 'ranking.png');
    resetCaptureArea(captureArea);
  });
};

const prepareCaptureArea = (captureArea) => {
  captureArea.style.position = "absolute";
  captureArea.style.left = "-9999px";
  captureArea.style.top = "-9999px";
  captureArea.style.width = "auto"; 
  captureArea.style.height = "auto"; 
  captureArea.style.display = "block";
};

const downloadCanvasAsImage = (canvas, filename) => {
  const link = document.createElement("a");
  link.href = canvas.toDataURL("image/png");
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const resetCaptureArea = (captureArea) => {
  captureArea.innerHTML = '';
  captureArea.style.position = "";
  captureArea.style.left = "";
  captureArea.style.top = "";
  captureArea.style.display = "none";
};

// COLONNES______________________________

const [showSO5Columns, setShowSO5Columns] = useState(true);

const so5Columns = Array.from({ length: 40 }, (_, index) => ({
  Header: `S${index + 1}`,
  accessor: `so5score${index + 1}`,
  Cell: ({ value }) => {
    const cellClassName = value >= 75 ? 'veryhigh' : value >= 60 ? 'high' : value >= 45 ? 'medium' : value >= 30 ? 'low' : value >= 20 ? 'verylow' : value >= 0 ? 'veryverylow' : '';
    return (
      <div className={cellClassName}>
        {value !== 'DNP' ? (!isNaN(value) ? Math.round(value) : '') : 'DNP'}
      </div>
    );
  },
  disableFilters: true
}));

const aaColumns = Array.from({ length: 40 }, (_, index) => ({
  Header: `AA${index + 1}`,
  accessor: `aascore${index + 1}`,
  Cell: ({ value }) => {
    const cellClassName = value >= 30 ? 'aaveryhigh' : value >= 20 ? 'aahigh' : value >= 10 ? 'aamedium' : value >= 0 ? 'aalow' : value >= -5 ? 'aaverylow' : value >= -100 ? 'aaveryverylow' : '';
    return (
      <div className={cellClassName}>
        {value !== 'DNP' ? (!isNaN(value) ? Math.round(value) : '') : 'DNP'}
        </div>
    );
  },
  disableFilters: true
}));

// AVERAGE

const CapColumn = {
  Header: 'CAP',
  accessor: (row) => {
    const so5Scores = Array.from({ length: 15 }, (_, index) => row[`so5score${index + 1}`]);
    const average = calculateAverage(so5Scores, 15);
    return isNaN(average) ? 0 : Math.round(average);
  },
  Filter: NumberRangeColumnFilter,
  filter: "between",
  disableFilters: false,
  enablesort:false
};

const averageSO5Column = {
  Header: 'SO5 AVG',
  accessor: (row) => {
    const so5Scores = Array.from({ length: numMatches }, (_, index) => row[`so5score${index + 1}`]);
    const average = calculateAverage(so5Scores, numMatches);
    return isNaN(average) ? 0 : Math.round(average);
  },
  Cell: ({ value }) => {
    const cellClassName = value >= 75 ? 'veryhigh' : value >= 60 ? 'high' : value >= 45 ? 'medium' : value >= 30 ? 'low' : value >= 20 ? 'verylow' : value >= 0 ? 'veryverylow' : '';
    return (<div className={cellClassName}>{value}</div>);
  },
  disableFilters: true
};

const averageAASColumn = {
  Header: 'AA AVG',
  accessor: (row) => {
    const aasScores = Array.from({ length: numMatches }, (_, index) => row[`aascore${index + 1}`]);
    const average = calculateAverage(aasScores, numMatches);
    return isNaN(average) ? 0 : Math.round(average);
  },
  Cell: ({ value }) => {
    const cellClassName = value >= 30 ? 'aaveryhigh' : value >= 20 ? 'aahigh' : value >= 10 ? 'aamedium' : value >= 0 ? 'aalow' : value >= -5 ? 'aaverylow' : value >= -100 ? 'aaveryverylow' : '';
    return (<div className={cellClassName}>{value}</div>);
  },
  disableFilters: true
};

// FREQUENCES

const so5FrequColumn = {
  Header: 'SO5 FREQU. (+' + so5Frequ + ')',
  accessor: (row) => {
    const so5Scores = Array.from({ length: numMatches }, (_, index) => row[`so5score${index + 1}`]);
    const frequency = calculateFrequency(so5Scores, so5Frequ, numMatches);
    return `${frequency.toFixed(0)}%`;
  },
  disableFilters: true,
  Cell: ({ value }) => {
    const percentage = parseFloat(value);
    return (
      <div className="bar-container">
        <div className="percentage-bar" style={{width: `${percentage}%`}}></div>
        <span className="percentage-text">{value}</span>
      </div>
    );
  }
};

const aaFrequColumn = {
  Header: 'AA FREQU. (+' + aaFrequ + ')',
  accessor: (row) => {
    const aasScores = Array.from({ length: numMatches }, (_, index) => row[`aascore${index + 1}`]);
    const frequency = calculateFrequency(aasScores, aaFrequ, numMatches);
    return `${frequency.toFixed(0)}%`;
  },
  disableFilters: true,
  Cell: ({ value }) => {
    const percentage = parseFloat(value);
    return (
      <div className="bar-container">
        <div className="percentage-bar" style={{width: `${percentage}%`}}></div>
        <span className="percentage-text">{value}</span>
      </div>
    );
  }
};

// PLAYED

const playedColumn = {
  Header: 'PLAYED',
  accessor: (row) => {
    const scores = Array.from({ length: numMatches }, (_, index) => row[`so5score${index + 1}`]);
    const playedPercentage = calculatePlayed(scores, numMatches);
    return `${playedPercentage.toFixed(0)}%`;
  },
  Filter: SliderColumnFilter,
  filter: filterGreaterThan,
  disableFilters: false,
  canSort: false,
  Cell: ({ value }) => {
    const percentage = parseFloat(value);
    return (
<div className="bar-container">
    <div className="percentage-bar" style={{width: `${percentage}%`}}></div>
    <div className="percentage-text-container">
        <span className="percentage-text">{value}</span>
    </div>
</div>

    );
  }
};

// PERF INDEX

const perfIndexColumn = {
  Header: 'PERF INDEX',
  accessor: (row) => {
    const so5Scores = Array.from({ length: numMatches }, (_, index) => row[`so5score${index + 1}`]);
    return calculatePerfIndex(so5Scores);
  },
  disableFilters: true,
  Cell: ({ value }) => {
    return value;
  }
};

// COLONNES

  const columns = [
    {
      Header: 'LEAGUE',
      accessor: 'league',
      disableFilters: true,
    },
    {
      Header: 'COUNTRY',
      accessor: 'country',
      disableFilters: true,
    },
    {
      Header: 'NAME',
      accessor: 'name',
      disableFilters: true,
  },
    {
      Header: 'CLUB',
      accessor: 'club',
      disableFilters: true,
    },
    {
      Header: 'POSITION',
      accessor: 'position',
      disableFilters: true,
      Cell: ({ value }) => {
        // Séparez la valeur en mots, convertissez chaque mot en majuscules et prenez les 3 premières lettres
        const formatted = value
          .split(' ')
          .map(word => word.toUpperCase().substring(0, 3))
          .join(' ');
        return formatted;
      }
    },    
    {
      Header: 'AGE',
      accessor: (row) => {
        const age = calculateAge(row.birthdate);
        return age ? parseInt(age, 10) : 'N/A';
      },
      Filter: NumberRangeColumnFilter,
      filter: 'between',
      disableFilters: false,
    },
    CapColumn,
    playedColumn,
    averageSO5Column,
    averageAASColumn,
    so5FrequColumn,
    aaFrequColumn,
    perfIndexColumn,
    {
      Header: 'LIMITED',
      accessor: 'limited',
      disableFilters: true,
      Cell: ({ value }) => {
        if (!value || value === "NaN" || Number.isNaN(parseFloat(value))) {
          return "No sale";
        }
        return parseFloat(value).toFixed(4);
      }
    },
    {
      Header: 'RARE',
      accessor: 'rare',
      disableFilters: true,
      Cell: ({ value }) => {
        if (!value || value === "NaN" || Number.isNaN(parseFloat(value))) {
          return "No sale";
        }
        return parseFloat(value).toFixed(3);
      }
    },
    ...(showSO5Columns ? so5Columns : []),
    ...(showSO5Columns ? [] : aaColumns),
  ];

return (

<div className="players-container">
  <div className="sticky-container">
    <Header 
      league={league}
      country={country}
      club={club}
      position={position}
      minsplayed={minsplayed}
      starter={starter}
      maxlimited={maxlimited}
      maxrare={maxrare}
      showSO5Columns={showSO5Columns} 
      setShowSO5Columns={setShowSO5Columns}
      onCaptureClicked={handleCapture}
    />
  </div>

  <div className="players-page">
    {isLoading ? (
      <div className="loading-spinner">
        <svg className="custom-logo2" version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2055.000000 203.000000" preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,203.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M693 2006 c-167 -32 -288 -91 -384 -187 -117 -116 -165 -251 -157
        -436 5 -127 34 -188 128 -277 74 -69 181 -128 389 -216 341 -142 411 -202 398
        -342 -18 -199 -234 -310 -498 -254 -113 24 -188 76 -231 159 -16 31 -19 55
        -17 116 2 42 0 82 -5 89 -5 9 -46 12 -147 12 -163 0 -153 8 -165 -132 -20
        -253 123 -441 391 -514 78 -21 317 -30 412 -15 223 34 390 135 495 296 61 94
        83 178 83 320 0 113 -2 123 -29 178 -19 36 -55 82 -98 123 -77 72 -162 118
        -401 218 -281 117 -344 155 -378 230 -34 75 -19 180 36 252 60 78 231 126 374
        105 186 -28 285 -131 280 -292 -1 -38 1 -73 5 -77 4 -4 70 -7 147 -7 111 0
        142 3 149 15 5 7 12 48 16 90 24 257 -127 461 -393 531 -101 27 -300 34 -400
        15z"/>
        <path d="M2207 2005 c-279 -53 -502 -254 -586 -529 -29 -93 -112 -758 -113
        -896 0 -68 6 -128 17 -170 52 -199 200 -344 404 -396 51 -13 99 -15 225 -12
        170 5 227 18 346 78 191 95 340 293 391 518 18 80 99 772 99 844 -1 272 -160
        483 -415 553 -86 23 -272 28 -368 10z m286 -302 c103 -46 164 -138 173 -261 8
        -92 -78 -792 -107 -880 -58 -174 -211 -283 -398 -283 -97 0 -134 9 -197 46
        -74 44 -127 132 -139 235 -6 55 71 724 96 835 40 173 172 307 331 334 78 14
        174 3 241 -26z"/>
        <path d="M18415 2014 c-11 -2 -45 -9 -75 -15 -123 -25 -249 -98 -343 -199 -72
        -78 -116 -154 -146 -255 -24 -78 -131 -933 -131 -1041 0 -249 151 -435 397
        -490 58 -13 104 -15 197 -11 205 8 329 60 462 192 85 85 137 170 168 275 19
        63 79 517 70 531 -8 13 -591 12 -599 -1 -4 -6 -9 -34 -12 -64 -5 -49 -4 -53
        18 -59 13 -4 117 -7 232 -7 114 0 207 -2 207 -4 0 -2 -11 -84 -24 -182 -34
        -245 -59 -310 -157 -405 -121 -119 -310 -172 -495 -138 -169 31 -286 146 -314
        310 -10 58 -6 111 44 519 31 250 63 484 71 520 63 265 311 427 600 390 107
        -13 188 -54 254 -126 68 -74 93 -153 90 -279 -2 -50 1 -96 5 -103 10 -17 111
        -16 125 2 18 22 26 176 12 248 -35 180 -164 316 -351 373 -53 16 -262 29 -305
        19z"/>
        <path d="M19825 2011 c-152 -25 -265 -78 -356 -170 -176 -177 -199 -462 -49
        -625 59 -64 129 -106 410 -244 400 -197 469 -267 458 -462 -6 -103 -44 -185
        -114 -249 -91 -84 -185 -118 -349 -127 -238 -15 -419 59 -493 200 -25 48 -27
        58 -25 174 l1 122 -63 0 c-76 0 -75 2 -82 -141 -5 -90 -2 -117 15 -175 41
        -132 145 -228 305 -281 77 -25 94 -27 262 -27 159 -1 189 2 260 22 284 82 448
        295 433 563 -12 195 -128 320 -434 465 -65 31 -177 85 -249 119 -161 76 -224
        125 -260 200 -25 50 -27 63 -23 143 11 237 178 366 473 365 219 0 354 -65 426
        -203 21 -40 24 -58 24 -157 l-1 -113 67 0 c78 0 74 -5 84 115 18 236 -107 404
        -350 470 -69 18 -295 28 -370 16z"/>
        <path d="M3258 1987 c-15 -15 -255 -1938 -243 -1956 4 -7 59 -11 151 -11 140
        0 145 1 154 23 5 12 30 193 55 402 25 209 48 390 50 403 5 22 7 22 180 22
        l174 0 91 -283 c50 -155 112 -345 138 -422 l47 -140 156 -3 c127 -2 158 0 162
        11 3 8 -59 201 -139 428 -79 228 -144 419 -144 424 0 6 25 19 55 30 172 58
        317 221 365 411 22 86 27 248 9 323 -39 172 -183 304 -369 337 -108 19 -873
        20 -892 1z m795 -288 c49 -13 115 -73 138 -124 26 -57 25 -177 -2 -250 -28
        -75 -99 -146 -176 -176 -53 -20 -79 -23 -296 -27 -131 -2 -243 0 -247 4 -5 5
        6 134 25 289 19 154 35 283 35 288 0 11 480 8 523 -4z"/>
        <path d="M5308 1983 c-25 -28 -850 -1933 -844 -1949 4 -11 36 -14 160 -14 184
        0 156 -21 246 193 l62 147 372 0 373 0 22 -133 c13 -72 27 -149 32 -169 l10
        -38 160 0 c135 0 161 2 166 16 6 17 -353 1918 -368 1947 -9 15 -29 17 -193 17
        -162 0 -185 -2 -198 -17z m232 -861 c44 -254 80 -471 80 -482 0 -20 -5 -20
        -285 -20 -164 0 -285 4 -285 9 0 9 385 935 396 953 3 5 8 8 10 5 2 -2 40 -211
        84 -465z"/>
        <path d="M6438 1987 c-15 -15 -255 -1938 -243 -1956 4 -7 59 -11 151 -11 140
        0 145 1 154 23 5 12 30 193 55 402 25 209 48 390 50 403 5 22 7 22 180 22
        l174 0 91 -283 c50 -155 112 -345 138 -422 l47 -140 156 -3 c127 -2 158 0 162
        11 3 8 -59 201 -139 428 -79 228 -144 419 -144 424 0 6 25 19 55 30 172 58
        317 221 365 411 22 86 27 248 9 323 -39 172 -183 304 -369 337 -108 19 -873
        20 -892 1z m795 -288 c49 -13 115 -73 138 -124 26 -57 25 -177 -2 -250 -28
        -75 -99 -146 -176 -176 -53 -20 -79 -23 -296 -27 -131 -2 -243 0 -247 4 -5 5
        6 134 25 289 19 154 35 283 35 288 0 11 480 8 523 -4z"/>
        <path d="M7948 1987 c-15 -15 -255 -1938 -243 -1956 10 -17 1269 -15 1283 2
        11 13 32 165 32 230 l0 37 -485 0 c-267 0 -485 4 -485 9 0 13 59 493 65 528
        l6 32 325 3 c297 3 327 5 338 21 15 20 42 228 33 251 -6 14 -47 16 -332 16
        l-325 0 0 33 c0 17 14 138 30 267 16 129 30 238 30 243 0 4 216 7 480 7 l480
        0 14 23 c8 12 20 73 27 135 11 97 11 115 -2 123 -8 5 -282 9 -636 9 -503 0
        -624 -3 -635 -13z"/>
        <path d="M9473 1988 c-9 -11 -244 -1899 -243 -1950 0 -15 10 -18 65 -18 53 0
        67 3 72 18 4 9 32 217 63 462 l55 445 259 3 c169 1 263 -1 270 -8 6 -6 80
        -214 166 -463 l155 -452 64 -3 c35 -2 68 1 73 6 5 5 -57 199 -147 457 -85 246
        -155 453 -155 460 0 7 17 16 38 20 135 24 300 157 364 295 160 338 23 680
        -292 730 -99 15 -795 13 -807 -2z m804 -143 c157 -47 242 -220 204 -417 -34
        -175 -164 -312 -329 -347 -54 -11 -652 -16 -652 -5 0 14 91 742 95 762 l5 22
        313 0 c249 0 324 -3 364 -15z"/>
        <path d="M11544 1992 c-13 -8 -864 -1940 -864 -1960 0 -9 20 -12 73 -10 l72 3
        86 200 86 200 429 3 c235 1 432 -2 436 -6 5 -5 24 -89 43 -187 19 -98 37 -186
        40 -196 5 -16 17 -19 71 -19 45 0 66 4 70 14 7 17 -365 1937 -378 1954 -11 13
        -145 17 -164 4z m180 -802 c64 -332 116 -612 116 -622 0 -17 -21 -18 -390 -18
        -279 0 -390 3 -390 11 0 11 497 1155 527 1212 8 16 16 27 18 25 2 -2 56 -275
        119 -608z"/>
        <path d="M12553 1988 c-9 -11 -244 -1899 -243 -1950 0 -15 10 -18 64 -18 37 0
        68 5 72 11 3 6 51 374 105 818 55 443 102 817 105 830 3 13 9 20 13 15 4 -5
        167 -344 361 -754 194 -410 373 -784 397 -832 l43 -88 64 0 c58 0 65 2 72 22
        17 54 240 1934 231 1946 -13 16 -111 16 -125 -1 -6 -7 -57 -385 -113 -840 -56
        -455 -105 -827 -109 -827 -4 0 -183 373 -399 828 -215 456 -397 834 -403 840
        -16 16 -123 15 -135 0z"/>
        <path d="M14243 1988 c-9 -11 -244 -1899 -243 -1950 0 -15 10 -18 64 -18 37 0
        68 5 72 11 5 9 94 679 94 715 0 17 333 325 341 316 4 -4 132 -234 284 -512
        152 -278 282 -511 287 -517 15 -19 147 -17 154 1 3 8 -132 264 -300 570 -168
        306 -306 562 -306 569 0 6 178 190 396 408 234 234 394 401 390 408 -4 6 -35
        11 -72 11 l-66 0 -236 -229 c-130 -126 -372 -359 -537 -519 -165 -159 -301
        -284 -303 -277 -2 8 24 235 58 506 34 270 59 498 56 505 -7 18 -119 19 -133 2z"/>
        <path d="M15733 1988 c-9 -11 -244 -1899 -243 -1950 0 -15 10 -18 64 -18 36 0
        68 5 72 11 10 16 246 1939 240 1955 -7 18 -119 19 -133 2z"/>
        <path d="M16273 1988 c-9 -11 -244 -1899 -243 -1950 0 -15 10 -18 64 -18 37 0
        68 5 72 11 3 6 51 374 105 818 55 443 102 817 105 830 3 13 9 20 13 15 4 -5
        167 -344 361 -754 194 -410 373 -784 397 -832 l43 -88 64 0 c58 0 65 2 72 22
        17 54 240 1934 231 1946 -13 16 -111 16 -125 -1 -6 -7 -57 -385 -113 -840 -56
        -455 -105 -827 -109 -827 -4 0 -183 373 -399 828 -215 456 -397 834 -403 840
        -16 16 -123 15 -135 0z"/>
        </g>
        </svg>
        <span className="loader"></span>
      </div>
    ) : (
      <Table data={filteredPlayersWithScores} columns={columns} />
    )}
  </div>
</div>
);};

export default Players;
