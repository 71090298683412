const leagues = {
  "leagues": [
    {
      "name": "D2",
      "countries": [
        {
          "name": "Germany",
          "clubs": [
            "Kieler SV Holstein 1900",
            "1. FC Magdeburg",
            "Karlsruher SC",
            "FC Schalke 04",
            "SC Paderborn 07",
            "SpVgg Greuther Fürth",
            "Düsseldorfer TuS Fortuna 1895",
            "FC Hansa Rostock",
            "1. FC Nürnberg",
            "SV 07 Elversberg",
            "1. FC Kaiserslautern",
            "Hannoverscher Sportverein 1896",
            "FC St. Pauli",
            "Hamburger SV",
            "SV Wehen Wiesbaden",
            "Hertha Berlin",
            "VfL Osnabrück",
            "Braunschweiger TSV Eintracht 1895"
          ]
        },
        {
          "name": "England",
          "clubs": [
            "Hull City AFC",
            "Sunderland AFC",
            "Leeds United FC",
            "Leicester City FC",
            "Plymouth Argyle FC",
            "Watford FC",
            "Preston North End FC",
            "Middlesbrough FC",
            "Norwich City FC 1",
            "Blackburn Rovers FC",
            "Millwall FC",
            "Coventry City FC",
            "Ipswich Town FC",
            "Sheffield Wednesday FC",
            "Huddersfield Town FC",
            "Birmingham City FC",
            "Swansea City AFC",
            "Rotherham United FC",
            "Southampton FC",
            "Cardiff City FC",
            "West Bromwich Albion FC",
            "Queens Park Rangers FC",
            "Bristol City FC",
            "Stoke City FC"
          ]
        },
        {
          "name": "France",
          "clubs": [
            "US du Littoral de Dunkerque",
            "Angers SCO",
            "En Avant Guingamp",
            "ESTAC Troyes",
            "Stade Malherbe Caen",
            "Rodez Aveyron Football",
            "Paris FC",
            "US Concarnoise",
            "Valenciennes FC",
            "FC Girondins de Bordeaux",
            "AJ Auxerre",
            "Grenoble Foot 38",
            "FC Annecy",
            "SC Bastia",
            "Amiens SC",
            "AC Ajaccio",
            "Pau FC",
            "AS Saint-Étienne",
            "US Quevilly Rouen Metropole",
            "Stade Lavallois Mayenne FC"
          ]
        },
        {
          "name": "Spain",
          "clubs": [
            "FC Cartagena",
            "Real Racing Club de Santander",
            "CD Leganés",
            "CD Tenerife",
            "SD Huesca",
            "Real Oviedo",
            "SD Eibar",
            "Levante UD",
            "SD Amorebieta",
            "Burgos CF",
            "FC Andorra",
            "AD Alcorcón",
            "Real Zaragoza",
            "Albacete Balompié",
            "Reial Club Deportiu Espanyol",
            "Villarreal CF II",
            "Racing Club de Ferrol",
            "Real Valladolid Club de Fútbol",
            "CD Eldense",
            "Elche CF",
            "Real Sporting de Gijón",
            "CD Mirandés"
          ]
        },
        {
          "name": "Italy",
          "clubs": [
            "US Città di Palermo",
            "FeralpiSalò Srl",
            "Ascoli Picchio FC 1898",
            "Spezia Calcio",
            "Urbs Sportiva Reggina 1914",
            "Catanzaro Calcio 2011",
            "FC Bari 1908",
            "FC Südtirol",
            "Venezia FC",
            "Calcio Como",
            "Reggio Audace FC",
            "Calcio Lecco 1912",
            "UC Sampdoria",
            "Modena FC",
            "Parma Calcio 1913",
            "US Cremonese",
            "AC Pisa 1909",
            "Cosenza Calcio",
            "AS Cittadella",
            "Unicusano Ternana Calcio"
          ]
        }
      ]
    },
    {
      "name": "CHAMPION EUROPE",
      "countries": [
        {
          "name": "Germany",
          "clubs": [
            "RB Leipzig",
            "1. FSV Mainz 05",
            "VfL Bochum 1848",
            "Bayer 04 Leverkusen",
            "FC Bayern München",
            "FC Augsburg",
            "VfB Stuttgart",
            "Borussia Dortmund",
            "VfL Wolfsburg",
            "Eintracht Frankfurt",
            "Borussia Mönchengladbach",
            "1. FC Köln",
            "1. FC Union Berlin",
            "1. FC Heidenheim 1846",
            "Sport-Club Freiburg",
            "TSG Hoffenheim",
            "SV Werder Bremen",
            "SV Darmstadt 1898"
          ]
        },
        {
          "name": "France",
          "clubs": [
            "FC Nantes",
            "Olympique de Marseille",
            "LOSC Lille",
            "Toulouse FC",
            "Olympique Lyonnais",
            "RC Lens",
            "Clermont Foot 63",
            "Stade de Reims",
            "FC Lorient",
            "FC Metz",
            "AS Monaco",
            "Paris Saint-Germain",
            "OGC Nice",
            "Le Havre AC",
            "Montpellier Hérault SC",
            "Stade Rennais F.C.",
            "RC Strasbourg Alsace",
            "Stade Brestois 29"
          ]
        },
        {
          "name": "England",
          "clubs": [
            "Wolverhampton Wanderers FC",
            "Brighton & Hove Albion FC",
            "Sheffield United FC",
            "Chelsea FC",
            "Fulham FC",
            "Burnley FC",
            "Manchester City FC",
            "Manchester United FC",
            "Liverpool FC",
            "Nottingham Forest FC",
            "AFC Bournemouth",
            "Crystal Palace FC",
            "Arsenal FC",
            "Aston Villa FC",
            "West Ham United FC",
            "Brentford FC",
            "Tottenham Hotspur FC",
            "Luton Town FC",
            "Newcastle United FC",
            "Everton FC"
          ]
        },
        {
          "name": "Spain",
          "clubs": [
            "CA Osasuna",
            "Real Madrid CF",
            "Granada CF",
            "Cádiz CF",
            "Girona FC",
            "Sevilla FC",
            "Valencia CF",
            "Villarreal CF",
            "UD Las Palmas",
            "Deportivo Alavés",
            "Real Sociedad de Fútbol",
            "Athletic Club Bilbao",
            "Getafe Club de Fútbol",
            "UD Almería",
            "Rayo Vallecano",
            "Real Club Deportivo Mallorca",
            "FC Barcelona",
            "Real Betis Balompié",
            "Real Club Celta de Vigo",
            "Club Atlético de Madrid"
          ]
        },
        {
          "name": "Italy",
          "clubs": [
            "SSC Napoli",
            "ACF Fiorentina",
            "Hellas Verona FC",
            "Bologna FC 1909",
            "SS Lazio",
            "US Sassuolo Calcio",
            "Frosinone Calcio",
            "US Salernitana 1919",
            "AS Roma",
            "AC Monza",
            "Cagliari Calcio",
            "Juventus FC",
            "FC Internazionale Milano",
            "Genoa CFC",
            "Udinese Calcio",
            "Atalanta Bergamasca Calcio",
            "Torino FC",
            "AC Milan",
            "US Lecce",
            "Empoli FC"
          ]
        }
      ]
    },
    {
      "name": "CHALLENGER EUROPE",
      "countries": [
        {
          "name": "Austria",
          "clubs": [
            "SK Austria Klagenfurt",
            "SC Austria Lustenau",
            "WSG Tirol",
            "FK Austria Wien",
            "LASK",
            "FC Red Bull Salzburg",
            "SK Rapid Wien",
            "RZ Pellets WAC",
            "FC Blau-Weiß Linz",
            "CASHPOINT SCR Altach",
            "TSV Egger Glas Hartberg",
            "SK Puntigamer Sturm Graz"
          ]
        },
        {
          "name": "Norway",
          "clubs": [
            "Vålerenga Fotball",
            "Viking FK",
            "Aalesunds FK",
            "Hamarkameratene Fotball",
            "FK Bodø / Glimt",
            "Molde FK",
            "Sarpsborg 08 FF",
            "Odds BK",
            "Lillestrøm SK",
            "FK Haugesund",
            "Strømsgodset IF",
            "SK Brann",
            "Stabæk Fotball",
            "Rosenborg BK",
            "Tromsø IL",
            "Sandefjord Fotball"
          ]
        },
        {
          "name": "Netherlands",
          "clubs": [
            "SBV Vitesse",
            "PSV Eindhoven",
            "Go Ahead Eagles",
            "Fortuna Sittard",
            "RKC Waalwijk",
            "FC Utrecht",
            "Sparta Rotterdam",
            "Almere City FC",
            "SBV Excelsior",
            "AFC Ajax",
            "Heracles Almelo",
            "PEC Zwolle",
            "Nijmegen Eendracht Combinatie",
            "FC Volendam",
            "SC Heerenveen",
            "Feyenoord Rotterdam",
            "Alkmaar Zaanstreek",
            "FC Twente"
          ]
        },
        {
          "name": "Belgium",
          "clubs": [
            "KRC Genk",
            "Oud-Heverlee Leuven",
            "RSC Anderlecht",
            "Royal Standard de Liège",
            "Royal Union Saint-Gilloise",
            "KV Kortrijk",
            "Royal Antwerp FC",
            "Racing White Daring Molenbeek 47",
            "Cercle Brugge KSV",
            "KAS Eupen",
            "Club Brugge KV",
            "KAA Gent",
            "YR KV Mechelen",
            "KVC Westerlo",
            "Sint-Truidense VV",
            "Sporting du Pays de Charleroi"
          ]
        },
        {
          "name": "Croatia",
          "clubs": [
            "NK Rudeš Zagreb",
            "NK Lokomotiva Zagreb",
            "NK Osijek",
            "HNK Hajduk Split",
            "GNK Dinamo Zagreb",
            "HNK Gorica",
            "NK Slaven Belupo Koprivnica",
            "NK Varaždin",
            "HNK Rijeka",
            "NK Istra 1961"
          ]
        },
        {
          "name": "Russia",
          "clubs": [
            "PFC CSKA",
            "FK Spartak Moskva",
            "FK Rubin Kazan",
            "FC Dynamo Moscow",
            "FK Nizhny Novgorod",
            "FK Sochi",
            "FK Orenburg",
            "FK Rostov",
            "FC Lokomotiv Moscow",
            "PFK Krylya Sovetov Samara",
            "FK Baltika Kaliningrad",
            "FK Fakel Voronezh",
            "FK Krasnodar",
            "FK Akhmat Grozny",
            "FK Ural Sverdlovskaya Oblast",
            "FK Zenit St. Petersburg"
          ]
        },
        {
          "name": "Scotland",
          "clubs": [
            "Rangers FC",
            "Celtic FC",
            "Ross County FC",
            "Dundee FC",
            "Saint Mirren FC",
            "Heart of Midlothian FC",
            "Kilmarnock FC",
            "Hibernian FC",
            "Motherwell FC",
            "Aberdeen FC",
            "Livingston FC",
            "Saint Johnstone FC"
          ]
        },
        {
          "name": "Portugal",
          "clubs": [
            "CF Estrela da Amadora",
            "Rio Ave FC",
            "Sporting Clube de Portugal",
            "FC Porto",
            "SC Farense",
            "Gil Vicente FC",
            "GD Chaves",
            "FC Famalicão",
            "GD Estoril Praia",
            "Moreirense FC",
            "Sporting Braga",
            "SL Benfica",
            "Vitória Guimarães SC",
            "FC Arouca",
            "Portimonense SC",
            "FC Vizela",
            "Casa Pia AC",
            "Boavista FC"
          ]
        },
        {
          "name": "Switzerland",
          "clubs": [
            "FC Lugano",
            "FC Winterthur",
            "Grasshopper Club Zürich",
            "FC Zürich",
            "FC Basel 1893",
            "Yverdon Sport FC",
            "FC Luzern",
            "FC Lausanne Sport",
            "BSC Young Boys",
            "FC Sankt Gallen 1879",
            "Servette FC",
            "FC Stade Lausanne-Ouchy"
          ]
        },
        {
          "name": "Turkey",
          "clubs": [
            "Trabzonspor Kulübü",
            "Hatay Spor Kulübü",
            "MKE Ankaragücü Spor Kulübü",
            "Samsunspor Kulübü",
            "Fenerbahçe Spor Kulübü",
            "Çaykur Rize Spor Kulübü",
            "Beşiktaş Jimnastik Kulübü",
            "Fatih Karagümrük Spor Kulübü",
            "Kayseri Spor Kulübü",
            "Atiker Konyaspor Kulübü",
            "İstanbulspor AŞ",
            "Alanyaspor Kulübü",
            "Antalyaspor Kulübü",
            "Galatasaray Spor Kulübü",
            "Gazişehir Gaziantep Futbol Kulübü",
            "Pendik Spor Kulübü",
            "Kasımpaşa Spor Kulübü",
            "Adana Demir Spor Kulübü",
            "Sivasspor Kulübü",
            "İstanbul Başakşehir Futbol Kulübü"
          ]
        },
        {
          "name": "Denmark",
          "clubs": [
            "FC Nordsjælland",
            "FC København",
            "Odense BK",
            "Vejle Boldklub",
            "Lyngby Boldklub",
            "Brøndby IF",
            "Hvidovre IF",
            "FC Midtjylland",
            "Randers FC",
            "Viborg FF",
            "Aarhus Gymnastikforening",
            "Silkeborg IF"
          ]
        }
      ]
    },
    {
      "name": "ASIA",
      "countries": [
        {
          "name": "China",
          "clubs": [
            "Wuhan Three Towns FC",
            "Shandong Luneng Taishan FC",
            "Qingdao Jonoon FC",
            "Chengdu Rongcheng F.C",
            "Tianjin Teda FC",
            "Shanghai SIPG FC",
            "Beijing Guoan FC",
            "Zhejiang Greentown FC",
            "Shijiazhuang Ever Bright FC",
            "Shenzhen FC",
            "Changchun Yatai FC",
            "Nantong Zhiyun FC",
            "Dalian Yifang FC",
            "Henan Jianye FC",
            "Shanghai Shenhua FC",
            "Meizhou Hakka FC"
          ]
        },
        {
          "name": "Japan",
          "clubs": [
            "Gamba Osaka",
            "Vissel Kobe",
            "Kashiwa Reysol",
            "Kashima Antlers",
            "Yokohama F. Marinos",
            "Albirex Niigata",
            "Kawasaki Frontale",
            "Yokohama FC",
            "Consadole Sapporo",
            "Sanfrecce Hiroshima",
            "Shonan Bellmare",
            "Kyoto Sanga FC",
            "Sagan Tosu",
            "Cerezo Osaka",
            "Urawa Red Diamonds",
            "Nagoya Grampus",
            "Avispa Fukuoka",
            "FC Tokyo"
          ]
        },
        {
          "name": "Korea",
          "clubs": [
            "Ulsan Hyundai Horang-i",
            "Gwangju FC",
            "Incheon United FC",
            "Pohang Steelers FC",
            "Suwon Samsung Bluewings FC",
            "Suwon FC",
            "Jeju United FC",
            "FC Seoul",
            "Gangwon FC",
            "Jeonbuk Hyundai Motors FC",
            "Daejeon Citizen FC",
            "Daegu FC"
          ]
        }
      ]
    },
    {
      "name": "AMERICA",
      "countries": [
        {
          "name": "Mexico",
          "clubs": [
            "Mazatlán FC",
            "CD Guadalajara",
            "Club Tijuana Xoloitzcuintles de Caliente",
            "Puebla FC",
            "Deportivo Toluca FC",
            "CF Tigres de la Universidad Autónoma de Nuevo León",
            "Club América",
            "CF Monterrey",
            "Club Necaxa",
            "Atlético San Luis",
            "Club León",
            "Atlas FC de Guadalajara",
            "Querétaro FC",
            "Club Santos Laguna",
            "Cruz Azul FC",
            "CF Pachuca",
            "Pumas de la Universidad Nacional Autonoma de Mexico",
            "FC Juárez"
          ]
        },
        {
          "name": "Ecuador",
          "clubs": [
            "SD Aucas",
            "Orense SC",
            "Barcelona SC",
            "CSD Independiente del Valle",
            "Gualaceo SC",
            "CD Técnico Universitario",
            "CS Emelec",
            "CD El Nacional",
            "CD Cuenca",
            "Mushuc Runa SC",
            "CD Universidad Católica del Ecuador",
            "Liga Deportiva Universitaria de Quito",
            "Cumbayá FC",
            "Guayaquil City FC",
            "Club Atlético Libertad",
            "Delfín SC"
          ]
        },
        {
          "name": "Argentina",
          "clubs": [
            "CA Sarmiento",
            "CSD Defensa y Justicia",
            "Club Atlético Vélez Sarsfield",
            "CA Unión de Santa Fe",
            "CA Banfield",
            "CA Tigre",
            "Godoy Cruz Antonio Tomba",
            "Argentinos Juniors",
            "CA Rosario Central",
            "Newell's Old Boys",
            "CA River Plate",
            "Instituto AC Córdoba",
            "CA Lanús",
            "Racing Club de Avellaneda",
            "CA Talleres de Córdoba",
            "Colón de Santa Fe",
            "CA Huracán",
            "CA Independiente",
            "Gimnasia y Esgrima La Plata",
            "CA Boca Juniors",
            "CA Barracas Central",
            "CA Central Córdoba de Santiago del Estero",
            "Arsenal de Sarandí",
            "CA Tucumán",
            "CA Platense",
            "Club Estudiantes de La Plata",
            "CA Belgrano de Córdoba",
            "CA San Lorenzo de Almagro"
          ]
        },
        {
          "name": "USA",
          "clubs": [
            "FC Cincinnati",
            "San Jose Earthquakes",
            "LA Galaxy",
            "Columbus Crew",
            "Real Salt Lake",
            "Nashville SC",
            "Colorado Rapids",
            "Toronto FC",
            "Austin FC",
            "CF Montreal",
            "Charlotte FC",
            "Houston Dynamo FC",
            "FC Dallas",
            "Inter Miami CF",
            "Orlando City SC",
            "St. Louis City SC",
            "Portland Timbers",
            "Chicago Fire FC",
            "New York Red Bulls",
            "Seattle Sounders FC",
            "DC United",
            "Minnesota United",
            "Sporting Kansas City ",
            "New York City FC",
            "New England Revolution",
            "Philadelphia Union",
            "Atlanta United",
            "Los Angeles FC",
            "Vancouver Whitecaps FC"
          ]
        },
        {
          "name": "Colombia",
          "clubs": [
            "Sociedad Anónima Deportiva América SA",
            "Jaguares de Córdoba FC",
            "Club Deportes Tolima SA",
            "Águilas Doradas Rionegro SA",
            "CD La Equidad Seguros SA",
            "Deportivo Boyacá Chicó FC SA",
            "CS Deportiva y Cultural de Pereira",
            "Club Atlético Nacional SA",
            "Deportivo Independiente Medellín",
            "Independiente Santa Fe SA",
            "Asociación Deportivo Cali",
            "AD Pasto",
            "Once Caldas SA",
            "CA Bucaramanga",
            "AD Unión Magdalena",
            "Millonarios FC SA",
            "CD Atlético Huila",
            "CD Popular Junior FC SA",
            "Envigado FC",
            "Alianza Petrolera FC"
          ]
        },
        {
          "name": "Chile",
          "clubs": [
            "CD Universidad Católica",
            "CD Unión La Calera",
            "CD O'Higgins",
            "CD Copiapó",
            "Everton de Viña del Mar",
            "CSD Colo-Colo",
            "CFP Universidad de Chile",
            "Club Deportivo Magallanes",
            "CD Palestino",
            "CD Coquimbo Unido",
            "Unión Española",
            "Deportivo Ñublense SADP",
            "CD Provincial Curicó Unido",
            "Audax Italiano La Florida SADP",
            "CD Cobresal",
            "CD Huachipato"
          ]
        },
        {
          "name": "Peru",
          "clubs": [
            "Club Universitario de Deportes",
            "CSD Carlos A. Manucci",
            "Escuela Municipal Deportivo Binacional",
            "CD Sport Huancayo",
            "Sport Boys Association",
            "Asociación Civil Real Atlético Garcilaso",
            "Club Alianza Atlético Sullana",
            "Club Sporting Cristal SAC",
            "Club Centro Deportivo Municipal",
            "CCyD Universidad Técnica de Cajamarca",
            "CD Universidad César Vallejo",
            "Club Deportivo Garcilaso",
            "Club Atlético Grau",
            "Asociación Deportiva Tarma",
            "Academia Deportiva Cantolao",
            "Club Alianza Lima",
            "CD Unión Comercio",
            "FBC Melgar",
            "Club Cienciano"
          ]
        },
        {
          "name": "Brazil",
          "clubs": [
            "Coritiba FBC",
            "SE Palmeiras",
            "Grêmio FB Porto Alegrense",
            "CR Vasco da Gama",
            "Cruzeiro EC",
            "SC Internacional",
            "Clube Atlético Bragantino",
            "São Paulo FC",
            "Fluminense FC",
            "Santos FC Sao Paulo",
            "Atlético Mineiro",
            "América FC (Minas Gerais)",
            "EC Bahia",
            "Fortaleza EC",
            "CR Flamengo",
            "Cuiabá EC",
            "Botafogo FR",
            "SC Corinthians Paulista",
            "Goiás EC",
            "Club Athletico Paranaense"
          ]
        }
      ]
    }
  ]
};

export default leagues;